import { DownloadService } from './../../services/download.service';
import { BackendService } from '@ssmm-shared/services/backend.service';
import {
  Component,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild,
  EventEmitter,
  Output
} from '@angular/core';
import {
  Observable,
  Subject,
  of as observableOf,
  Subscription,
  EMPTY
} from 'rxjs';
import { UploadDocument } from '@ssmm-shared/data/models/document/upload-document.interface';
import { SelectEvent, FileRestrictions } from '@progress/kendo-angular-upload';
import {
  takeUntil,
  mergeMap,
  tap,
  switchMap,
  map,
  catchError
} from 'rxjs/operators';
import { DocumentListItem } from './data/document-list-item.interface';
import { DocumentSubscriptionItem } from './data/document-subscription-item.interface';
import { DownloadDocument } from '@ssmm-shared/data/models/document/download-document.interface';
import { YesNoDialogComponent } from '@ssmm-shared/dialogs/yes-no-dialog/yes-no-dialog.component';
import { ToastNotificationService } from '@ssmm-shared/services/toast-notification.service';

@Component({
  selector: 'ssmm-upload-docs-dialog',
  templateUrl: './upload-docs-dialog.component.html',
  styleUrls: ['./upload-docs-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadDocsDialogComponent implements OnDestroy {
  @ViewChild(YesNoDialogComponent)
  deleteDocCancelUploadDialog: YesNoDialogComponent;

  @Input() title: string;

  @Output() closed = new EventEmitter<void>();

  canUserUploadDocs: boolean;
  downloadedFiles: DownloadDocument[] = [];
  downloadingFileNames: string[] = [];
  deletingFileNames: string[] = [];
  uploadRestrictions: FileRestrictions = {
    maxFileSize: 10485760
  };
  documentToDelete: DocumentListItem | undefined;

  private readonly _unsubscribe$ = new Subject<void>();

  private _originalDocs: DocumentListItem[] = [];
  private _docUploadUri$: Observable<string>;
  private _fileUploads: DocumentSubscriptionItem[] = [];

  private _isDialogOpen: boolean;
  private _docs: DocumentListItem[];

  constructor(
    private _cdr: ChangeDetectorRef,
    private _bs: BackendService<File, File>,
    private _toasterService: ToastNotificationService,
    private _ds: DownloadService
  ) {}

  get isDialogOpen(): boolean {
    return this._isDialogOpen;
  }

  get docs(): DocumentListItem[] {
    if (!this._docs) {
      this._docs = [];
    }

    return this._docs;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  open(
    docs$: Observable<UploadDocument[]>,
    docUploadUri: Observable<string> = null
  ): void {
    this._isDialogOpen = true;
    this._docUploadUri$ = docUploadUri;
    this.canUserUploadDocs = !!docUploadUri;
    this._cdr.markForCheck();

    this.initUploadedDocs(docs$).subscribe(() => this._cdr.markForCheck());
  }

  close(): void {
    this._isDialogOpen = false;
    this._docs = null;
    this._originalDocs.length = 0;
    this.downloadingFileNames.length = 0;
    this.downloadedFiles.length = 0;
    this.deletingFileNames.length = 0;

    if (this._fileUploads.length) {
      this._fileUploads.forEach(f => f.subscription.unsubscribe());
      this._fileUploads.length = 0;
      this._toasterService.warn('Unfertige Uploads wurden abgebrochen.');
    }

    this._cdr.markForCheck();

    this.closed.emit();
  }

  downloadDoc(dli: DocumentListItem): void {
    // If already downloaded blob, just open cached blob
    if (this.downloadedFiles[dli.name]) {
      this._ds.openDoc('doc-' + dli.name, dli.name);
      return;
    }

    this.downloadingFileNames[dli.name] = true;

    dli.item
      .pipe(
        takeUntil(this._unsubscribe$),
        map(doc => doc._links.mm_dokument_content.href),
        switchMap(uri => this._bs.getDocumentSafeUrl(uri)),
        tap(doc => (this.downloadedFiles[dli.name] = doc)),
        tap(() => (this.downloadingFileNames[dli.name] = null)),
        tap(() => this._cdr.detectChanges())
      )
      .subscribe(() => this._ds.openDoc('doc-' + dli.name, dli.name));
  }

  deleteDocCancelUploadRequested(listItem: DocumentListItem): void {
    this.documentToDelete = listItem;
    this._isDialogOpen = false;
    this.deleteDocCancelUploadDialog.open(
      `Wollen Sie das Dokument "${this.documentToDelete?.name}" jetzt löschen?`
    );
  }

  resetDeleteDocCancelUpload(): void {
    this._isDialogOpen = true;

    delete this.documentToDelete;
  }

  deleteDocCancelUpload(): void {
    this._isDialogOpen = true;

    if (!this.documentToDelete) {
      return;
    }

    const documentName = this.documentToDelete.name;

    // Check for running upload
    const currentUploadIndex = this._fileUploads.findIndex(
      f => f.name === documentName
    );

    this.deletingFileNames[documentName] = true;

    // Cancel upload
    if (currentUploadIndex >= 0) {
      this._fileUploads[currentUploadIndex].subscription.unsubscribe();
      this._fileUploads = this.getFileUploadItemsWithout(documentName);

      const existingDocIndex = this.docs.findIndex(
        d => d.name === documentName
      );
      const originalDocIndex = this._originalDocs.findIndex(
        od => od.name === documentName
      );

      // Restore original document, if exists
      if (originalDocIndex >= 0) {
        this._docs[existingDocIndex] = this._originalDocs[originalDocIndex];
      } else {
        // Delete from list view otherwise
        this._docs = this.getDocumentsWithout(documentName);
      }

      this.deletingFileNames[documentName] = null;
      this._cdr.markForCheck();
      return;
    }

    // Delete document
    this.documentToDelete.item
      .pipe(
        takeUntil(this._unsubscribe$),
        switchMap(doc => this.deleteDocument(doc))
      )
      .subscribe(deletedDoc => {
        if (!deletedDoc) {
          this._toasterService.errorGeneric();
          return;
        }

        this._toasterService.success(
          `Das Dokument ${deletedDoc.name} wurde gelöscht.`
        );

        // Delete from existing docs list
        this._docs = this.getDocumentsWithout(documentName);

        // Delete from original docs list
        this._originalDocs = this.getOriginalItemsWithout(deletedDoc.name);

        // Delete from cached docs list
        this._docUploadUri$[documentName] = null;

        // Delete from deleting docs list
        this.deletingFileNames[documentName] = null;

        this._cdr.markForCheck();
      });
  }

  docToUploadSelected(fileSelectEvent: SelectEvent): void {
    const amountUserIsUploading = fileSelectEvent.files.length;

    let filesToUpload = fileSelectEvent.files;

    // Check max file size 5MB
    const tooLargeItems = filesToUpload.filter(
      f => f.size > this.uploadRestrictions.maxFileSize
    );
    if (tooLargeItems.length) {
      this._toasterService.warn(
        `Die Maximale Dateigröße beträgt ${
          this.uploadRestrictions.maxFileSize / 1048576
        }MB.`
      );
      filesToUpload = filesToUpload.filter(
        f => !tooLargeItems.some(i => i.name === f.name)
      );
    }

    // Check max amount of files
    if (amountUserIsUploading > 5 - this.docs.length) {
      filesToUpload = filesToUpload.slice(0, 5 - this.docs.length);
      this._toasterService.warn('Sie können nur max. 5 Dokumente hochladen.');
    }

    let amountFilesWithNonUtf8CharsFileName = 0;

    filesToUpload.forEach(fileToUpload => {
      if (
        fileToUpload.name.length < encodeURIComponent(fileToUpload.name).length
      ) {
        amountFilesWithNonUtf8CharsFileName++;
        return;
      }

      // If already upload in progress, cancel existing
      const existingUploadIndex = this._fileUploads.findIndex(
        upload => upload.name === fileToUpload.name
      );
      const existingDocIndex = this.docs.findIndex(
        d => d.name === fileToUpload.name
      );
      const originalDocIndex = this._originalDocs.findIndex(
        od => od.name === fileToUpload.name
      );

      // Item is currently uploading
      if (existingUploadIndex >= 0) {
        this.replaceCurrentlyUploadingItem(
          existingUploadIndex,
          fileToUpload.name,
          originalDocIndex,
          existingDocIndex
        );
      }

      // Temp add to docs so that it appears in UI and can be cancelled again
      // if not already previously uploaded
      if (existingDocIndex < 0) {
        this._docs = [
          ...this._docs,
          this.getNewUploadDocument(fileToUpload.name, null)
        ];
      } else {
        // Reset state and links
        this.docs[existingDocIndex].item = observableOf(<UploadDocument>{
          name: fileToUpload.name
        });
      }

      this._fileUploads.push(
        this.getDocumentSubscriptionItem(
          fileToUpload.name,
          fileToUpload.rawFile,
          originalDocIndex
        )
      );
    });

    // tslint:disable-next-line:early-exit
    if (amountFilesWithNonUtf8CharsFileName > 0) {
      this._toasterService.warn(
        `${amountFilesWithNonUtf8CharsFileName} Dateien mit
         Sonderzeichen im Dateinamen konnten nicht hochgeladen werden.`
      );
    }
  }

  private initUploadedDocs(
    docs$: Observable<UploadDocument[]>
  ): Observable<UploadDocument> {
    return docs$.pipe(
      takeUntil(this._unsubscribe$),
      mergeMap(obs => obs),
      tap(doc => this.docs.push(this.getNewUploadDocument(doc.name, doc))),
      // Backup in case we cancel an existing file update
      tap(() => (this._originalDocs = this.docs))
    );
  }

  private getNewUploadDocument(
    fileName: string,
    doc: UploadDocument
  ): DocumentListItem {
    const newDoc = doc ? doc : <UploadDocument>{ name: fileName };
    return <DocumentListItem>{
      name: fileName,
      item: observableOf(newDoc)
    };
  }

  private getOriginalItemsWithout(documentName: string): DocumentListItem[] {
    return this._originalDocs.filter(od => od.name !== documentName);
  }

  private getDocumentsWithout(documentName: string): DocumentListItem[] {
    return this._docs.filter(d => d.name !== documentName);
  }

  private getFileUploadItemsWithout(
    docName: string
  ): DocumentSubscriptionItem[] {
    return this._fileUploads.filter(upload => upload.name !== docName);
  }

  private deleteDocument(doc: UploadDocument): Observable<UploadDocument> {
    return this._bs
      .deleteItem(doc._links.mm_dokument_delete.href)
      .pipe(switchMap(wasDeleted => (wasDeleted ? observableOf(doc) : null)));
  }

  private getDocumentSubscriptionItem(
    fileName: string,
    fileToUpload: File,
    originalDocIndex: number
  ): DocumentSubscriptionItem {
    return <DocumentSubscriptionItem>{
      name: fileName,
      subscription: this.uploadDocument(fileToUpload, originalDocIndex)
    };
  }

  private uploadDocument(
    fileToUpload: File,
    originalDocIndex: number
  ): Subscription {
    return this._docUploadUri$
      .pipe(
        takeUntil(this._unsubscribe$),
        switchMap(uri => this._bs.uploadDocument(uri, fileToUpload)),
        catchError(() => {
          this._toasterService.errorGeneric();
          this._docs = this._docs.filter(d => d.name !== fileToUpload.name);
          this._cdr.markForCheck();

          return EMPTY;
        })
      )
      .subscribe(doc => {
        const existingDocIndex = this._docs.findIndex(d => d.name === doc.name);

        // Replace temp file with actual item
        this._docs[existingDocIndex] = this.getNewUploadDocument(doc.name, doc);

        // If doc existed before, update original doc
        if (originalDocIndex >= 0) {
          this._originalDocs[originalDocIndex] = this._docs[existingDocIndex];
        } else {
          // Add to original documents list
          this._originalDocs.push(this.getNewUploadDocument(doc.name, doc));
        }

        // Remove from upload list
        this._fileUploads = this.getFileUploadItemsWithout(doc.name);

        // Delete from cached docs list
        this._docUploadUri$[fileToUpload.name] = null;

        this._cdr.markForCheck();
      });
  }

  private replaceCurrentlyUploadingItem(
    existingUploadIndex: number,
    fileName: string,
    originalDocIndex: number,
    existingDocIndex: number
  ): void {
    // Stop upload
    this._fileUploads[existingUploadIndex].subscription.unsubscribe();
    this._fileUploads = this.getFileUploadItemsWithout(fileName);

    // Restore original document, if available
    if (originalDocIndex < 0) {
      return;
    }

    this._docs[existingDocIndex] = this._originalDocs.filter(
      od => od.name === fileName
    )[0];
  }
}
