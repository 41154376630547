<!-- Search Input Control -->
<div class="row ml-0 mr-0">
  <div class="row ml-0 mr-0">
    <div class="mr-3">
      <input
        kendoTextBox
        placeholder="Name..."
        #userSearch
        (keyup)="userSearchChanged.emit(userSearch.value)"
      />
    </div>

    <div *ngIf="isBeauftragteFilterable" class="row ml-0">
      <div class="mr-4 mt-auto mb-auto">
        <input
          #cbxIsBeauftragteFilterableCtl
          id="cbx-is-beauftragte-filterable"
          type="checkbox"
          kendoCheckBox
          (change)="isBeauftragteFilterableChanged($event.target.checked)"
        />
        <label class="k-checkbox-label" [for]="'cbx-is-beauftragte-filterable'"
          >Nur Beauftragte anzeigen</label
        >
      </div>

      <div class="row ml-0" *ngIf="cbxIsBeauftragteFilterableCtl.checked">
        <div class="mr-3">
          <kendo-dropdownlist
            [data]="sportarten"
            [defaultItem]="dropdownDefaultSportartItem"
            [textField]="'text'"
            [valueField]="'value'"
            (selectionChange)="setSportartFilter($event)"
          >
          </kendo-dropdownlist>
        </div>
        <div class="mr-3">
          <kendo-dropdownlist
            #wkFilterCtl
            [data]="wettkaempfe"
            [defaultItem]="dropdownDefaultWettkampfItem"
            [textField]="'text'"
            [valueField]="'value'"
            [disabled]="!selectedSport"
            (selectionChange)="setWettkampfFilter($event)"
          >
          </kendo-dropdownlist>
        </div>
        <div class="mr-3">
          <kendo-dropdownlist
            [data]="geoEbenen"
            [defaultItem]="dropdownDefaultEbeneItem"
            [textField]="'text'"
            [valueField]="'value'"
            (selectionChange)="setGeoEbeneFilter($event)"
          >
          </kendo-dropdownlist>
        </div>
        <div class="mr-3">
          <kendo-dropdownlist
            #geoElementeFilterCtl
            [data]="geoElemente"
            [defaultItem]="selectedGeoEbene | geoElementeDefaultItem"
            [textField]="'text'"
            [valueField]="'value'"
            [disabled]="!selectedGeoEbene || selectedGeoEbene === 'LA'"
            (selectionChange)="setGeoElementeFilter($event)"
          >
          </kendo-dropdownlist>
        </div>
      </div>
    </div>

    <!-- User List -->
    <kendo-grid
      class="mt-3"
      [data]="gridData"
      [pageable]="true"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sortable]="{
        allowUnsort: true,
        mode: 'single'
      }"
      [sort]="state.sort"
      (dataStateChange)="dataStateChange($event)"
    >
      <kendo-grid-messages
        [noRecords]="userSearch.value ? noSearchResultMsg : searchNotStartedMsg"
      ></kendo-grid-messages>
      <kendo-grid-column field="vorname" title="Vorname" [width]="160">
      </kendo-grid-column>
      <kendo-grid-column field="nachname" title="Nachname" [width]="160">
      </kendo-grid-column>
      <kendo-grid-column title="E-Mail" field="email">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="email-label">
            <a href="mailto:{{ dataItem.email }}">{{ dataItem.email }}</a>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Funktionen">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div *ngFor="let role of dataItem.funktionen">
            {{ role }}
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="100" *ngIf="canViewDetails">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="align-content-center">
            <button
              class="awesome-btn-default"
              kendoButton
              themeColor="primary"
              (click)="openDetails(dataItem)"
            >
              <fa-icon [icon]="['fas', 'eye']"></fa-icon>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="canCreateNutzer ? 162 : 100"
        *ngIf="isUserSelection"
      >
        <ng-template kendoGridHeaderTemplate *ngIf="canCreateNutzer">
          <div class="create-nutzer-button-wrapper">
            <button
              class="awesome-btn-green btn-custom-action"
              kendoButton
              themeColor="primary"
              (click)="openUserCreationAndAssignmentDialog()"
            >
              Nutzer erstellen
            </button>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="align-content-center">
            <button
              class="awesome-btn-default"
              kendoButton
              themeColor="primary"
              (click)="selectUser(dataItem)"
            >
              <fa-icon [icon]="['fas', 'check']"></fa-icon>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>

    <!-- User details -->
    <ssmm-user-details-dialog #userDetailsDialog></ssmm-user-details-dialog>
  </div>
</div>

<!-- User creation and asssignment dialog -->
<ssmm-user-creation-and-assignment-dialog
  (assignUserRequested)="assignUserRequested($event)"
></ssmm-user-creation-and-assignment-dialog>
