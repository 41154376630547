import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountProperties'
})
export class AmountPropertiesPipe implements PipeTransform {
  transform(
    obj: { [key: string]: number | null },
    ..._unusedParams: unknown[]
  ): number {
    return Object.keys(obj).filter(o => obj[o] >= 1).length;
  }
}
