import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssmm-pictogram',
  templateUrl: './pictogram.component.html'
})
export class PictogramComponent {
  @Input() uri: string;

  @Input() height = '25px';
  @Input() width = '25px';
}
