import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { HttpParams } from '@angular/common/http';
import { BackendSortOrder } from '@ssmm-shared/services/data/backend-sort-order.enum';
import {
  distinctUntilChanged,
  debounceTime,
  tap,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { BackendService } from '@ssmm-shared/services/backend.service';
import { User } from '@ssmm-shared/data/models/user/user.interface';
import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  Output,
  Input,
  OnDestroy
} from '@angular/core';
import { LogService } from '@ssmm-shared/services/log/log.service';

import { State, SortDescriptor } from '@progress/kendo-data-query';
import {
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';
import { UserDetailsDialogComponent } from '@ssmm-shared/dialogs/user-details-dialog/user-details-dialog.component';
import { Observable, Subject } from 'rxjs';
import { UserListBackendResponse } from './models/user-list-backend-response.interface';
import { KendoDropdownItem } from '@ssmm-shared/data/kendo-dropdown-item.interface';
import defaultDropdownItemFactory from '@ssmm-shared/factories/default-dropdown-item-factory';
import { UserCreationAndAssignmentDialogComponent } from '@ssmm-shared/views/user-creation-and-assignment-dialog/user-creation-and-assignment-dialog.component';
import { UserCreationAndAssignmentDialogData } from '@ssmm-shared/views/user-creation-and-assignment-dialog/data/user-creation-and-assignment-dialog-data.interface';
import { UserSelectionService } from '../../../user-management/services/user-selection.service';

@Component({
  selector: 'ssmm-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  @ViewChild('userSearch') userSearchValue: ElementRef;
  @ViewChild(UserDetailsDialogComponent)
  userDetailsDialog: UserDetailsDialogComponent;

  @ViewChild('wkFilterCtl') wkFilterCtl: DropDownListComponent;
  @ViewChild('geoElementeFilterCtl')
  geoElementeFilterCtl: DropDownListComponent;
  @ViewChild(UserCreationAndAssignmentDialogComponent)
  userCreationAndAssignmentDialogComponent: UserCreationAndAssignmentDialogComponent;

  @Output() userSelected = new EventEmitter<User>();

  @Input() isUserSelection: boolean;
  @Input() canViewDetails: boolean;
  @Input() canCreateNutzer: boolean;
  @Input() beauftragung: string;
  @Input() nutzerErstellenUrl: string;
  @Input() getUsersUri: string;
  @Input() isBeauftragteFilterable: boolean;

  userSearchChanged: EventEmitter<string> = new EventEmitter<string>();
  gridData: GridDataResult;
  searchNotStartedMsg =
    'Starten Sie die Nutzersuche durch Eingabe des Namens der Person, die Sie suchen.';
  noSearchResultMsg = 'Es wurden keine Nutzer gefunden.';

  readonly defaultSorting: SortDescriptor = {
    field: 'nachname',
    dir: 'asc'
  };

  state: State = {
    skip: 0,
    take: 10,
    sort: [this.defaultSorting]
  };

  dropdownDefaultItem: KendoDropdownItem = defaultDropdownItemFactory(
    'Bitte wählen'
  );

  dropdownDefaultSportartItem: KendoDropdownItem = defaultDropdownItemFactory(
    'Alle Sportarten'
  );

  dropdownDefaultWettkampfItem: KendoDropdownItem = defaultDropdownItemFactory(
    'Alle Wettkämpfe'
  );

  dropdownDefaultEbeneItem: KendoDropdownItem = defaultDropdownItemFactory(
    'Alle Ebenen'
  );

  sportarten: KendoDropdownItem[];
  wettkaempfe: KendoDropdownItem[];
  geoEbenen: KendoDropdownItem[];
  geoElemente: KendoDropdownItem[];

  selectedSport: string | null;
  selectedWk: number | null;
  selectedGeoEbene: string | null;
  selectedGeoElementId: string | null;

  public get searchTerm(): string {
    return this.userSearchValue.nativeElement.value;
  }

  /* Grid settings */
  private _amountItemsTotal: number;

  private _unsubscribe = new Subject<void>();
  private _isBeaOnlySelected: boolean;

  constructor(
    private _backendServiceDetails: BackendService<User, null>,
    private _backendService: BackendService<UserListBackendResponse, User>,
    private _log: LogService,
    private _userSelectionService: UserSelectionService
  ) {}

  ngOnInit(): void {
    this.initUserSearchChanged();

    // Remove default query params
    this.getUsersUri = this.getUsersUri.split('?')[0];
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  /**
   * Paging Kendo Grid event
   * @see https://www.telerik.com/kendo-angular-ui/components/grid/data-binding/
   * @param state The adapted state of the Grid view
   */
  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;

    if (!this.state.sort[0].dir) {
      this.state.sort[0] = this.defaultSorting;
    }

    this.getUsersOp().subscribe();
  }

  openDetails(dataItem: User): void {
    if (
      !dataItem ||
      !dataItem._links ||
      !dataItem._links.mm_nutzerdetails ||
      !dataItem._links.mm_nutzerdetails.href
    ) {
      this._log.error(
        'User Details could not be loaded for no link to details was provided'
      );
      return;
    }

    const user$ = this._backendServiceDetails
      .getItem(dataItem._links.mm_nutzerdetails.href)
      .pipe();

    this.userDetailsDialog.open(user$);
  }

  selectUser(selectedUser: User): void {
    this.userSelected.emit(selectedUser);
  }

  isBeauftragteFilterableChanged(isChecked: boolean): void {
    this._isBeaOnlySelected = isChecked;

    if (!isChecked) {
      this.selectedSport = null;
      this.selectedWk = null;
      this.selectedGeoEbene = null;
      this.selectedGeoElementId = null;
    }

    if (!isChecked && !this.searchTerm) {
      this.setGridData([], 0);
      return;
    }

    this.getUsersOp().subscribe();
  }

  setSportartFilter($event: KendoDropdownItem): void {
    this.selectedSport = $event.value?.toString();

    this.selectedWk = null;
    this.wettkaempfe = null;
    this.wkFilterCtl.value = this.dropdownDefaultItem;

    this.getUsersOp().subscribe();
  }

  setWettkampfFilter($event: KendoDropdownItem): void {
    this.selectedWk = $event.value ? Number($event.value) : null;

    this.getUsersOp().subscribe();
  }

  setGeoEbeneFilter($event: KendoDropdownItem): void {
    this.selectedGeoEbene = $event.value?.toString();

    this.selectedGeoElementId = null;
    this.geoElemente = null;
    this.geoElementeFilterCtl.value = this.dropdownDefaultItem;

    this.getUsersOp().subscribe();
  }

  setGeoElementeFilter($event: KendoDropdownItem): void {
    this.selectedGeoElementId = $event.value?.toString();

    this.getUsersOp().subscribe();
  }

  openUserCreationAndAssignmentDialog(): void {
    this.userCreationAndAssignmentDialogComponent.open(
      this.beauftragung,
      this.nutzerErstellenUrl
    );
  }

  assignUserRequested(
    userCreationAndAssignmentDialogData: UserCreationAndAssignmentDialogData
  ): void {
    this._userSelectionService.shouldAssignKr =
      userCreationAndAssignmentDialogData.shouldAssignKr;
    this.userSelected.emit(userCreationAndAssignmentDialogData.user);
  }

  private getUsersOp(): Observable<UserListBackendResponse> {
    return this.getUsers(
      this.getUsersUri,
      this.state.take,
      this.state.skip,
      this.searchTerm,
      this.state.sort[0].field,
      BackendSortOrder[this.state.sort[0].dir.toUpperCase()],
      this._isBeaOnlySelected,
      this.selectedSport,
      this.selectedWk?.toString(),
      this.selectedGeoEbene,
      this.selectedGeoElementId
    ).pipe(takeUntil(this._unsubscribe));
  }

  private getUsers(
    url: string,
    pageSize: number,
    skip: number,
    searchTerm: string,
    sortField: string,
    sortOrder: string,
    beaOnly: boolean,
    selectedSport: string | null,
    selectedWkId: string | null,
    selectedGeoEbene: string | null,
    selectedGeoElement: string | null
  ): Observable<UserListBackendResponse> {
    let params = new HttpParams();
    params = params.append('offset', skip.toString());
    params = params.append('limit', pageSize.toString());
    params = params.append('sortField', sortField);
    params = params.append('sortOrder', sortOrder);

    params = params.append('beaOnly', (beaOnly ?? false)?.toString());

    if (searchTerm) {
      params = params.append('term', searchTerm);
    }
    if (selectedSport) {
      params = params.append('sportartId', selectedSport);
    }
    if (selectedWkId) {
      params = params.append('wettkampfId', selectedWkId);
    }
    if (selectedGeoEbene) {
      params = params.append('geoEbeneId', selectedGeoEbene);
    }
    if (selectedGeoElement) {
      params = params.append('geoElementId', selectedGeoElement);
    }

    return this._backendService.getItems2(url, params).pipe(
      tap(res => (this._amountItemsTotal = res.range ? res.range.count : 0)),
      tap(res => {
        if (!searchTerm && !beaOnly) {
          this.setGridData([], 0);
          return;
        }

        this.setGridData(res.items, this._amountItemsTotal);
      }),
      tap(res => {
        this.sportarten = res.sportarten.map(
          s => <KendoDropdownItem>{ text: s.name, value: s.kuerzel }
        );
        this.wettkaempfe = res.wettkaempfe.map(
          wk => <KendoDropdownItem>{ text: wk.name, value: wk.id }
        );
        this.geoEbenen = res.geoEbenen.map(
          g => <KendoDropdownItem>{ text: g, value: g }
        );
        this.geoElemente = res.geoElemente.map(
          g => <KendoDropdownItem>{ text: g.name, value: g.kuerzel }
        );
      })
    );
  }

  private setGridData(users: User[], amountTotal: number): void {
    this.gridData = {
      data: users,
      total: amountTotal
    };
  }

  private initUserSearchChanged(): void {
    this.userSearchChanged
      .pipe(
        takeUntil(this._unsubscribe),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => (this.state.skip = 0)),
        switchMap(() => this.getUsersOp())
      )
      .subscribe();
  }
}
