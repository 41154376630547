<div class="row">
  <!-- Fixture header -->
  <div class="col-md-6">
    <p>Ebene &mdash; Runde</p>
    <p>Titel der Veranstaltung</p>
    <p *ngIf="showCompetitionName">Wettkampf</p>
    <p>Austragungstermin</p>
    <p>Austragungsort</p>
    <p>Sportstätte</p>
    <p>Adresse</p>
    <p>Bestätigungsschluss</p>
    <p>Teilnehmerliste</p>
    <p>Einzureichen bis</p>
    <p>Wettkampfleitung</p>
    <p>E-Mail</p>
    <p>Mobiltelefon</p>
  </div>
  <!-- Fixture data -->
  <div class="col-md-6 fixture-data">
    <ng-template #emptyCellTemplate><p>&mdash;</p></ng-template>
    <p>{{ fixture.geoEbene }} &mdash; {{ fixture.runde | fixtureRound }}</p>
    <p>{{ fixture.name }}</p>
    <p *ngIf="showCompetitionName">{{ competitionName }}</p>
    <p *ngIf="fixture.datum; else emptyCellTemplate">
      {{ fixture.datum | date : 'dd.MM.yyyy' }}
    </p>
    <p *ngIf="fixture.ort; else emptyCellTemplate">
      {{ fixture.ort?.plz }} {{ fixture.ort?.name }}
    </p>
    <p *ngIf="fixture.sportstaette; else emptyCellTemplate">
      {{ fixture.sportstaette }}
    </p>
    <p *ngIf="fixture.adresse; else emptyCellTemplate">
      {{ fixture.adresse }}
    </p>
    <p *ngIf="fixture.bestaetigungsschluss; else emptyCellTemplate">
      {{ fixture.bestaetigungsschluss | date : 'dd.MM.yyyy' }}
    </p>
    <p>
      {{ fixture.teilnehmerlisteBis ? 'vorab online' : 'zum Termin' }}
    </p>
    <p *ngIf="fixture.teilnehmerlisteBis; else emptyCellTemplate">
      {{ fixture.teilnehmerlisteBis | date : 'dd.MM.yyyy' }}
    </p>
    <ng-container
      *ngIf="
        fixture.leitung.nutzer
          | toUser
            : fixture.leitung.vorname
            : fixture.leitung.nachname
            : fixture.leitung.email
            : fixture.leitung.mobiltelefon as userParsed;
        else emptyLeitungCellTemplate
      "
    >
      <p>{{ userParsed | userFullName }}</p>
      <p>{{ userParsed.email }}</p>
      <p>{{ userParsed.mobiltelefon }}</p>
    </ng-container>

    <ng-template #emptyLeitungCellTemplate>
      <p>&mdash;</p>
      <p>&mdash;</p>
      <p>&mdash;</p>
    </ng-template>
  </div>
</div>
