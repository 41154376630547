<!-- We need an container before the *ngIf to make sure, the component is pre-rendered in all cases -->
<ng-container>
  <!-- Assign Kreise Dialog -->
  <kendo-dialog [title]="title" *ngIf="isVisible" (close)="close()">
    <div *ngIf="dropdownDefaultEbeneItem" class="row mb-3">
      <label class="col-md-9 col-form-label"
        >Mannschaften zur Organisation von Veranstaltungen auf der aktuellen
        Austragungsebene abgeben ODER direkt auf die nächste Ebene
        melden?</label
      >
      <div class="col-md-3">
        <!-- GeoEbene selection -->
        <kendo-dropdownlist
          class="form-control ddl-geo-ebenen"
          [data]="geoEbeneList"
          [defaultItem]="dropdownDefaultEbeneItem"
          [textField]="'text'"
          [valueField]="'value'"
          (selectionChange)="onGeoEbeneChanged($event)"
        ></kendo-dropdownlist>
      </div>
    </div>
    <form [formGroup]="searchForm" novalidate>
      <kendo-grid
        scrollable="virtual"
        [data]="beasFiltered$ | async"
        [pageable]="false"
      >
        <kendo-grid-messages
          [noRecords]="
            !(selectedBeas$ | async)?.length
              ? 'Keine Beauftragten verfügbar.'
              : ''
          "
        ></kendo-grid-messages>

        <kendo-grid-column>
          <ng-template kendoGridHeaderTemplate>
            <ssmm-kendo-grid-search
              [control]="formModel.userSearchValue"
              (search)="search()"
            ></ssmm-kendo-grid-search>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.nutzer.vorname }}</span>
            <span>&nbsp;</span>
            <span>{{ dataItem.nutzer.nachname }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column>
          <ng-template kendoGridHeaderTemplate>
            <ssmm-kendo-grid-search
              [control]="formModel.geoSearchValue"
              (search)="search()"
            ></ssmm-kendo-grid-search>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex">
              <div class="mr-auto ml-3 my-auto">
                {{ dataItem.geo | geoNamesSeparated : ', ' }}
              </div>
              <div class="ml-auto mr-3">
                <button
                  class="awesome-btn-default"
                  kendoButton
                  themeColor="primary"
                  type="button"
                  (click)="userSelected(dataItem)"
                >
                  <fa-icon [icon]="['fas', 'check']"></fa-icon>
                </button>
              </div>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>

      <kendo-dialog-actions [layout]="'stretched'">
        <button type="button" kendoButton (click)="close()">Abbrechen</button>
      </kendo-dialog-actions>
    </form>
  </kendo-dialog>
</ng-container>
