import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'ssmm-user-creation-form',
  templateUrl: './user-creation-form.component.html'
})
export class UserCreationFormComponent {
  @Input() wasSaveRequested: boolean;
  @Input() isRbBeauftragung: boolean;

  tooltipContent = `Eingaberegeln:<br>
    * Nur Ziffern.<br>
    * Keine Leerzeichen.<br>
    * Muss mit einer "0" starten.`;

  // #region [Getters]
  get userFirstNameRef(): AbstractControl {
    return this.controlContainer.control.get('firstName');
  }

  get userLastNameRef(): AbstractControl {
    return this.controlContainer.control.get('lastName');
  }

  get userEmailRef(): AbstractControl {
    return this.controlContainer.control.get(['emailGroup', 'email']);
  }

  get userEmailRepeatRef(): AbstractControl {
    return this.controlContainer.control.get(['emailGroup', 'emailRepeat']);
  }

  get userEmailGroupRef(): AbstractControl {
    return this.controlContainer.control.get('emailGroup');
  }

  get userPhoneRef(): AbstractControl {
    return this.controlContainer.control.get('phone');
  }

  get shouldAssignKrRef(): AbstractControl {
    return this.controlContainer.control.get('shouldAssignKr');
  }

  get isDataProcessingAllowedRef(): AbstractControl {
    return this.controlContainer.control.get('isDataProcessingAllowed');
  }

  constructor(public controlContainer: ControlContainer) {}
}
